import { updateModuleConfigurations } from "../../components/ui/ModuleConfigurationUI/actions";
import { updateModulesData } from "../../components/ui/ModuleConfigurationUI/moduleConfiguration.utils";
import store from "../../store";
import {
  requestStart,
  requestStop,
  toastError,
  toastSuccess,
} from "../../store/features/global/global";
import {
  getDataFromStore,
  loadResponseIntoStore,
} from "../../utils/helpers/dynamicActionHelper";
import { cloneDeep } from "lodash";
export const MODULE_CONFIGURATION = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-sub-container",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            margin: "2px 10px",
            border: "1px solid",
            borderRadius: "10px",
            borderColor: "rgb(226, 228, 237)",
            height: "80vh",
          },
        },
        componentProps: {
          content: [
            {
              type: "div",
              id: "basic-details-sub-container",

              staticProps: {
                className: "common-content-container ",
                customStyle: {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px 10px",
                  height: '8vh'
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      variant: "h3",
                      content: "Planning Version Configuration",
                      customStyle: {
                        marginBottom: '0px !important',
                      },
                    },
                  },

                  // {
                  //   type: "div",
                  //   id: "basic-details-header-left",
                  //   staticProps: {
                  //     customStyle: {
                  //       display: "flex",
                  //       width: "400px",
                  //       justifyContent: "center",
                  //       alignItems: "center",
                  //     },
                  //   },
                  //   componentProps: {
                  //     content: [
                  //       {
                  //         type: "text",
                  //         staticProps: {
                  //           content: "Config Completed",
                  //           customStyle: {
                  //             fontSize: "14px",
                  //           },
                  //         },
                  //       },
                  //       {
                  //         type: "text",
                  //         staticProps: {
                  //           content: "0%",
                  //           customStyle: {
                  //             paddingLeft: "5px",
                  //             paddingRight: "5px",
                  //             fontSize: "18px",
                  //             border: "1px solid black",
                  //             borderRadius: "10px",
                  //             marginLeft: "20px",
                  //           },
                  //         },
                  //       },
                  //       {
                  //         id: "decision-dashboard-edit-button",
                  //         type: "iconButton",
                  //         staticProps: {
                  //           variant: "secondary",
                  //           icon: "help",
                  //           customStyle: {
                  //             backgroundColor: "white",
                  //             width: "34px",
                  //             height: "34px",
                  //             marginLeft: "10px",
                  //             marginBottom: "5px",
                  //             borderRadius: "50%",
                  //             color: "black",
                  //             transform: "scale(1.2)",
                  //           },
                  //           display: {
                  //             type: "controlled",
                  //             default: {
                  //               show: true,
                  //               enabled: true,
                  //             },
                  //           },
                  //         },
                  //         functionProps: [
                  //           {
                  //             functionName: "onClick",
                  //             actions: [
                  //               {
                  //                 type: "reducer_function",
                  //                 responseFormatter: [
                  //                   {
                  //                     destination: "reducer",
                  //                     dataKey: "componentDisplayControls",
                  //                     subjectId: "slect-channel-dropdown",
                  //                     dataType: "object",
                  //                     value: {
                  //                       show: true,
                  //                     },
                  //                   },
                  //                 ],
                  //               },
                  //             ],
                  //           },
                  //         ],
                  //       },
                  //     ],
                  //   },
                  // },
                ],
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                //margin: "20px 0px",
              },
            },
            {
              type: "div",
              staticProps: {
                customStyle: {
                 //display: "flex",
                 height: 'calc(80vh - 23vh)',
                  padding: "10px",
                }
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    staticProps: {
                      customStyle: {
                        display: "flex",
                        padding: "3px",
                        width: '100%'
                      },
                    },
                    componentProps: {
                      content: [
                        {
                          type: "text",
                          staticProps: {
                            variant: "h4",
                            customStyle: {
                              fontWeight: '450'
                            },
                            content:
                              "Configure the planning versions valid for the client",
                          },
                        },
                      ]
                    }
                  },
                  {
                    type: "moduleConfigurationUI",
                    dataProps: {
                      data: {
                        type: "derived",
                        dataKey: "moduleData",
                        subjectId: "1",
                        subjectidNested: "array_agg",
                        dataType: "array",
                      },
                    },
                  },
                ],
              },
            },
            
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                //margin: "20px 0px",
              },
            },
            {
              type: "div",
              id: "cancel-next-button-container",
              staticProps: {
                customStyle: {
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  //marginRight: "100px",
                  padding:'10px',
                  height: '5vh'
                },
              },
              componentProps: {
                content: [
                  {
                    alignment: "right",
                    id: "strategy-workbench-create-button",
                    type: "button",
                    staticProps: {
                      variant: "secondary",
                      label: "Cancel",
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "landing-screen",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    alignment: "right",
                    id: "strategy-workbench-create-button",
                    type: "button",
                    staticProps: {
                      variant: "primary",
                      label: "Save",
                      display: {
                        type: "controlled",
                        default: {
                          show: true,
                          enabled: true,
                        },
                      },
                      customStyle: {
                        marginLeft: "10px",
                      },
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "custom",
                            function: async () => {
                              store.dispatch(requestStart());
                              const data = getDataFromStore(
                                "reducer",
                                "modulesTobeEnabled"
                              );
                              if (
                                data &&
                                Array.isArray(data) &&
                                data.length > 0
                              ) {
                                const response = await Promise.all(
                                  data.map(async (item) => {
                                    const body = {
                                      id: 69,
                                      parameters: {
                                        id: item.id,
                                        is_deleted: item.value,
                                      },
                                    };
                                    return await updateModuleConfigurations(
                                      body
                                    );
                                  })
                                );
                                let modulesData = cloneDeep(
                                  getDataFromStore("reducer", "moduleData")
                                );
                                response.forEach((res, i) => {
                                  if (res) {
                                    modulesData[1].array_agg = updateModulesData(
                                      data,
                                      i,
                                      modulesData[1].array_agg
                                    );
                                    store.dispatch(
                                      toastSuccess(
                                        `${data[i].name} Module Configuration updated successfully`
                                      )
                                    );
                                  } else {
                                    store.dispatch(
                                      toastError(
                                        `Unable to update ${data[i].name} Module Configuration`
                                      )
                                    );
                                  }
                                });
                                loadResponseIntoStore(
                                  [
                                    {
                                      destination: "reducer",
                                      dataKey: "moduleData",
                                      overwrite: true,
                                    },
                                  ],
                                  modulesData
                                );
                                // loadResponseIntoStore(
                                //   [
                                //     {
                                //       destination: "reducer",
                                //       dataKey: "modulesTobeEnabled",
                                //       overwrite: true,
                                //     },
                                //   ],
                                //   null
                                // );
                              } else {
                                store.dispatch(
                                  toastError(
                                    "Please update any modules to enable/disable"
                                  )
                                );
                              }
                              store.dispatch(requestStop());
                            },
                          },
                          // {
                          //   type: "api_function",
                          //   runOnLoad: false,
                          //   apiEndPoint: "model-update",
                          //   apiMethod: "post",
                          //   params: [
                          //     {
                          //       source: "fixed",
                          //       dataType: "object",
                          //       apiRequestKey: "id",
                          //       value: 69,
                          //     },
                          //     {
                          //       source: "reducer",
                          //       apiRequestKey: "parameters",
                          //       apiRequestKeyNested: "id",
                          //       sourceId: "modulesTobeEnabled",
                          //       subjectId: "id",
                          //     },
                          //     {
                          //       source: "reducer",
                          //       apiRequestKey: "parameters",
                          //       apiRequestKeyNested: "is_deleted",
                          //       sourceId: "modulesTobeEnabled",
                          //       subjectId: "value",
                          //     },
                          //   ],
                          // },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 70,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "fixed",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    value: "null",
                    dataType: "basic",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "moduleData",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
