import React from "react";
import "./Loader.scss";
import { Spinner } from "impact-ui";

const LoadingOverlay = (props) => {
	const { showLoader, message } = props;
	if (!showLoader) {
		return "";
	}

	const loadingMessage = message || "";

	return (
		<div className={`loader-fullscreen loader-with-message ${props.className}`}>
			<div className="loader">Loading more data...</div>
			<div className="loader-message">
				<p>{loadingMessage}</p>
			</div>
			{/* <Spinner size="large" /> */}
		</div>
	);
};

export default LoadingOverlay;
