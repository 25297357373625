import React, { memo, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom-v5-compat";
import LoadingOverlay from "../../ui/loader/Loader";

const ProtectedRoutes = (props) => {
	const isAuthenticated = useMemo(() => localStorage.getItem("token"), [props.isAuthenticated])
	let location = useLocation();
	if(!isAuthenticated) {
		return <Navigate to="/login" state={{from : location}} replace/>
	}
	return props.isAuthenticated ? props.children : <LoadingOverlay className="custom-loader-with-message" showLoader={true} message="Authenticating User..."/> ;
}

export default memo(ProtectedRoutes);
