import { cloneDeep, isEmpty } from "lodash";
import Group1 from "./../../../assets/images/Group1.png";
import Target from "./../../../assets/images/Target.png";
import Calendar from "./../../../assets/images/calendar.png";
export const removeDuplicateModuleData = (moduleData, selectedModules) => {
  if (!moduleData) {
    return;
  }
  let updatedModule = [];
  if (!isEmpty(selectedModules)) {
    moduleData.forEach((module) => {
      const findModule = selectedModules.find(
        (selectedModule) => selectedModule.id === module.id
      );
      if (findModule && findModule.is_deleted !== module.value) {
        updatedModule.push(module);
      }
    });
  }
  return updatedModule;
};

export const getModuleName = (name) => {
  let updatedName = "";
  switch (name) {
    case "in_season":
      updatedName = "In Season";
      break;
    case "pre_season":
      updatedName = "Pre Season";
      break;
    default:
      updatedName = "Target Plan";
  }
  return updatedName;
};

export const getModuleLabel = (label) => {
  let updatedLabel = { heading: "", subHeading: "" };
  switch (label) {
    case "In-Season":
      updatedLabel = { heading: "WF", subHeading: "Inseason Planning" };
      break;
    case "Pre-Season":
      updatedLabel = { heading: "WP", subHeading: "Preseason Planning" };
      break;
    default:
      updatedLabel = { heading: "TP", subHeading: "Target Planning" };
  }
  return updatedLabel;
};

export const updateModulesData = (data, i, modules) => {
    if(!modules) {
        return [];
    }
    const updatedModules = cloneDeep(modules);
    const findModule = updatedModules.find((module) => module.id === data[i].id);
    if(findModule) {
        findModule.is_deleted = data[i].value;
    }
    return updatedModules;
}

export const getIconImage = (label) => {
  if(!label) {
    return Group1;
  }
  let icon = null;
  switch(label) {
    case "In-Season":
      icon = Group1;
      break;
    case "Pre-Season":
      icon =  Calendar;
      break;
    default:
      icon = Target;
  }
  return icon;
}