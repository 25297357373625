import { attr } from "highcharts";
import { DEFAULT_BASIC_DETAILS_FORM_DATA } from "../Constants";

export const LANDING_SCREEN = {
  screen_composition: {
    components: [
      {
        type: "div",
        id: "basic-details-content-emptystate",
        staticProps: {
          className: "common-content-container ",
          customStyle: {
            // margin: "2px 10px",
            // border: "1px solid",
            // borderRadius: "10px",
            // borderColor: "#808080",
            // height: "600px",
          },
        },
        componentProps: {
          content: [
            {
              type: "text",
              staticProps: {
                content: "Plan Smart Configurator",
                customStyle: {
                  fontWeight: "600",
                  fontSize: "18px",
                  display: "flex",
                  marginBottom: "24px",
                },
              },
            },
            {
              type: "text",
              staticProps: {
                marginTop: "20px",
                fontWeight: "500",
                content: "Client Level Configuration",
                customStyle: {
                  fontSize: "16px",
                  display: "flex",
                  fontWeight: "500",
                  marginBottom: "16px",
                },
              },
            },
            {
              type: "div",
              id: "configuration-wrapper",
              staticProps: {
                customStyle: {
                  display: "flex",
                  gap: "24px",
                  flexWrap: "wrap",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "module-configuration",
                    componentProps: {
                      content: [
                        {
                          type: "card",
                          staticProps: {
                            mainHeading: "Planning Version Configuration",
                            subHeading: "Configure the planning versions valid for the client",
                            customStyle: {
                              // margin: "0px",
                              // width: "380px",
                              // height: "110px",
                              // fontSize: "14px",
                              // fontWeight: "600",
                            },
                          },
                        },
                      ],
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "module-configuration",
                          },
                        ],
                      },
                    ],
                  },
                  // {
                  //   type: "div",
                  //   id: "version-configuration",
                  //   componentProps: {
                  //     content: [
                  //       {
                  //         type: "card",
                  //         staticProps: {
                  //           mainHeading: "Version Configuration",
                  //           subHeading: "Maintain versions of data for client",
                  //           customStyle: {
                  //             margin: "2px 10px",
                  //             width: "380px",
                  //             height: "110px",
                  //           },
                  //         },
                  //       },
                  //     ],
                  //   },
                  //   functionProps: [
                  //     {
                  //       functionName: "onClick",
                  //       actions: [
                  //         {
                  //           type: "redirect",
                  //           link: "module-configuration",
                  //         },
                  //       ],
                  //     },
                  //   ],
                  // },
                  {
                    type: "div",
                    id: "out-of-box-kpi",
                    componentProps: {
                      content: [
                        {
                          type: "card",
                          staticProps: {
                            mainHeading: "Global KPI Configuration",
                            subHeading:
                              "Manage the KPI's defination for the client",
                            customStyle: {
                              // width: "380px",
                              // height: "110px",
                              // fontSize: "14px",
                              // fontWeight: "600",
                            },
                          },
                        },
                      ],
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "add-new-configurator",
                          },
                          {
                            type: "reducer_function",
                            responseFormatter: [
                              {
                                destination: "reducer",
                                dataKey: "sidebarNavigationData",
                                value: {
                                  id: 1,
                                  title: "KPI Configurator",
                                  sub_sections: [
                                    {
                                      id: 2,
                                      title: "KPI Details",
                                      config_id: "9",
                                      config_type: "filterConfig",
                                    },
                                  ],
                                },
                                dataType: "array",
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "20px 0px",
              },
            },
            {
              type: "text",
              staticProps: {
                variant: "h4",
                marginTop: "20px",
                content: "Module Level Configuration",
              },
            },
            {
              type: "div",
              id: "configuration-wrapper",
              staticProps: {
                customStyle: {
                  display: "flex",
                  gap: "24px",
                  flexWrap: "wrap",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "div",
                    id: "filter-module-configuration",
                    componentProps: {
                      content: [
                        {
                          type: "card",
                          staticProps: {
                            mainHeading: "Module Filter Configuration",
                            subHeading:
                              "Set up the filters so that a new module plan can be seen",
                            customStyle: {
                              //margin: "2px 10px",
                              //width: "380px",
                              //height: "110px",
                            },
                          },
                        },
                      ],
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "filter-module-configuration",
                          },
                        ],
                      },
                    ],
                  },
                  {
                    type: "div",
                    id: "dashboard-module-configuration",
                    componentProps: {
                      content: [
                        {
                          type: "card",
                          staticProps: {
                            mainHeading: "Module Dashboard Configuration",
                            subHeading:
                              "Configure the dashboard for each module",
                            customStyle: {
                              //margin: "2px 10px",
                              //width: "380px",
                              //height: "110px",
                            },
                          },
                        },
                      ],
                    },
                    functionProps: [
                      {
                        functionName: "onClick",
                        actions: [
                          {
                            type: "redirect",
                            link: "dashboard-module-configuration",
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            },
            
            {
              id: "create-product-group-divider",
              type: "divider",
              staticProps: {
                margin: "40px 0px 32px 0px",
              },
            },
            {
              type: "div",
              staticProps: {
                customStyle: {
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexWrap: "wrap",
                  width: "100%",
                  marginBottom: "16px",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "text",
                    staticProps: {
                      content: "KPI Configurations",
                      customStyle: {
                        fontSize: "16px",
                        display: "flex",
                        fontWeight: "500",
                      },
                    },
                  },
                  {
                    type: "div",
                    componentProps: {
                      content: [
                        {
                          type: "button",
                          staticProps: {
                            variant: "primary",
                            label: "Add new configuration",
                            customStyle: {},
                            display: {
                              type: "controlled",
                              default: {
                                show: true,
                                enabled: true,
                              },
                            },
                          },
                          functionProps: [
                            {
                              functionName: "onClick",
                              actions: [
                                {
                                  type: "reducer_function",
                                  responseFormatter: [
                                    {
                                      destination: "reducer",
                                      dataKey: "fromSelectedConfig",
                                      value: false,
                                      dataType: "basic",
                                    },
                                  ],
                                },
                                {
                                  type: "redirect",
                                  link: "add-new-config",
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  },
                ],
              },
            },
            ,
            {
              type: "div",
              staticProps: {
                customStyle: {
                  display: "flex",
                },
              },
              componentProps: {
                content: [
                  {
                    type: "configDesign",
                    staticProps: {
                      isConfig: true,
                    },
                    dataProps: {
                      data: {
                        type: "derived",
                        dataKey: "configs",
                        dataType: "array",
                      },
                    },
                  },
                ],
              },
            },
          ],
        },
        functionProps: [
          {
            functionName: "onLoad",
            actions: [
              {
                type: "api_function",
                runOnLoad: true,
                apiEndPoint: "model-data",
                apiMethod: "post",
                params: [
                  {
                    source: "fixed",
                    dataType: "object",
                    apiRequestKey: "id",
                    value: 70,
                  },
                  {
                    source: "filters",
                    dataType: "object",
                    apiRequestKey: "parameters",
                  },
                  {
                    source: "fixed",
                    apiRequestKey: "parameters",
                    apiRequestKeyNested: "id",
                    value: "null",
                    dataType: "basic",
                  },
                ],
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "configs",
                    dataType: "array",
                    overwrite: true,
                  },
                ],
              },
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "select-channel",
                    subjectId: "selectedItems",
                    value: [],
                  },
                ],
              },
              {
                type: "reducer_function",
                responseFormatter: [
                  {
                    destination: "reducer",
                    dataKey: "newConfig",
                    type: "object",
                    value: {
                      screenName: "LANDNG_SCREEN",
                      flow: "add",
                      configDetails: {},
                      formData: DEFAULT_BASIC_DETAILS_FORM_DATA,
                      "select-channel": {},
                      "select-module": {},
                      updateExisting: true,
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
};
