import { API_CORE } from "../../../../utils/axios";

export const updateModuleConfigurations = async (body) => {
    const data  = await  API_CORE({
      url: `/plan-smart/model-update`,
      method: "POST",
      data: body,
    }).then((response) => response.data)
    .then((result) => {
      return result && result;
    }).catch((error) => {
      console.error(error, "Unable to update module configuration");
      return null;
    });
    return data;
  };